import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import PageWrapper from '../components/pageWrapper'
import Footer from '../components/footer'
import { media } from '../utils/mediaQueries'

const Text = styled.div`
  margin-top: 20px;

  ${ media.medium`
    margin-top: 40px;
  ` }

  h1 {
    margin-top: 2.25rem;
  }

  h2 {
    margin-top: 1.875rem;
  }
  
  h3 {
    margin-top: 1.5rem;
  }

  a {
    color: #6C82FF;
    text-decoration: underline;
  }
`

const PrivacyPolicyPage = ({ data: { datoCmsPrivacyPolicy: { text } } }) => {
  return (
    <>
      <PageWrapper>
        <Text dangerouslySetInnerHTML={{ __html: text }}/>
      </PageWrapper>
      <Footer/>
    </>
  )
}

export const query = graphql`
  query PrivacyPolicyPage {
    datoCmsPrivacyPolicy {
      text
    }
  }
`

export default PrivacyPolicyPage
